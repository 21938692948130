import React, { useEffect, useState } from 'react';
import ScrollToTop from './utils/scrollToTop';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Error from './pages/others/Error';
// import ComingSoon from './pages/others/ComingSoon';
// import Maintenance from './pages/others/Maintenance';
import AppLink from './pages/others/AppLink';
import ContactSupport from './pages/others/ContactSupport';
import TeamAirbag from 'pages/team/TeamAirbag';
import ContactAirbag from 'pages/contact/ContactAirbag';
import HomeAirbag from 'pages/homes/HomeAirbag';
import ServiceOne from 'pages/service/ServiceOne';
import ServiceTwo from 'pages/service/ServiceTwo';
import ServiceThree from 'pages/service/ServiceThree';
import TerminosCondiciones from 'pages/about/TerminosCondiciones';
import AvisoPrivacidad from 'pages/about/AvisoPrivacidad';
import DemoRequest from 'pages/contact/DemoRequest';
import Pricing from 'pages/pricing/Pricing';
import News from 'pages/news/News';
import TestimonialAirbag from 'pages/testimonial/TestimonialAirbag';
import Landing from 'pages/landing/Landing';
import LandingCol from 'pages/landing-col/Landing';
import Quotation from 'pages/quotation/Quotation';

const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState(
    Cookies.get('i18next') || 'es'
  );

  const useLocale = () => {
    const { t } = useTranslation();
    return t('html_lang');
  };

  const locale = useLocale();

  useEffect(() => {
    const len = Cookies.get('i18next') || 'es';
    len === 'es' ? setCurrentLanguage('es') : setCurrentLanguage('en');
  }, [locale]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }} />
      <Router>
        <ScrollToTop>
          <Switch>
            {/* <Route
              path={`${process.env.PUBLIC_URL + '/coming-soon'}`}
              component={ComingSoon}
            /> */}
            {/* <Route
              path={`${process.env.PUBLIC_URL + '/maintenance'}`}
              component={Maintenance}
            /> */}
            <Route
              path={`${process.env.PUBLIC_URL + '/equipo'}`}
              component={TeamAirbag}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/contacto'}`}
              component={ContactAirbag}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/app'}`}
              component={ServiceOne}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/logistics'}`}
              component={ServiceTwo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/x-ray'}`}
              component={ServiceThree}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/terms'}`}
              component={TerminosCondiciones}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/privacy'}`}
              component={AvisoPrivacidad}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/solicitud-de-demo'}`}
              component={DemoRequest}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/applink'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/contactar-soporte'}`}
              component={ContactSupport}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/testimonios'}`}
              component={TestimonialAirbag}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/'}`}
              component={HomeAirbag}
            />{' '}
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/planes'}`}
              component={Pricing}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/noticias'}`}
              component={News}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/landing-app'}`}
              component={Landing}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/landing-col'}`}
              component={LandingCol}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/cotizacion'}`}
              component={Quotation}
            />
            {/* Growth deeplinks */}
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/hbPotential'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/hbInactive'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/hbRecoverable'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/rest'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/lost1'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/lost2'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/risk'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/star'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/meteorite'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/ok'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/attention'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/inactive'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/new'}`}
              component={AppLink}
            />
            <Route
              path={`${process.env.PUBLIC_URL + '/activate_app/newUser'}`}
              component={AppLink}
            />
            <Route exact component={Error} />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
